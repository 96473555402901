import * as React from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { NamedRouteObject } from "./router";

interface NavigationProps {
	routes: NamedRouteObject[]
	children: any
}

export const Navigation: React.FC<NavigationProps> = ({routes, children}) => {
	const location = useLocation();
	const navigate = useNavigate();
	return (
		<Box>
			<Select
				label="page"
				value={location?.pathname || "/"}
				onChange={(ev) => navigate(ev.target.value)}
			>
				{
					routes.filter((route) => !route?.hidden).map((route) => (
						<MenuItem key={`navigation-${route.path}`} value={route.path}>{route.name}</MenuItem>
					))
				}
			</Select>
			{children}
		</Box>
	)
}