import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AuthState {
	token: string | null
}

export const initialState: AuthState = {
	token: null
};


export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		}
	}
})

export const { setToken } = authSlice.actions

export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;