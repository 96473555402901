import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		mode: 'dark',
		secondary: {
			main: '#00FF00', // Example neon green
			},
	},
	shadows: Array(25).fill('none') as any
});