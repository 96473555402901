import * as schema from '../../schema/res-api';
import { EditType } from '../types';

export interface ResAPISchema {
	paths: schema.paths
}

export type ResGetOperations = {
	[K in keyof schema.paths]: schema.paths[K]['get'];
};

export type ResGetResponses = {
	[K in keyof ResGetOperations]: ResGetOperations[K] extends { responses: infer R} ? R: never;
}

export type PossibleResData = ResGetResponses[keyof ResGetResponses][200]["content"]["application/json"];


interface ResDataEditBase {
	editType: EditType,
	tableName: string,
};

export interface ResDataCreate<T> extends ResDataEditBase{
	value: T
};

export interface ResDataUpdate<T> extends ResDataEditBase{
	pk: number,
	value: T
};

export function isResDataUpdate(obj: any): obj is ResDataUpdate<PossibleResData> {
	return 'value' in obj && 'pk' in obj;
}

export interface ResDataDelete extends ResDataEditBase{
	pk: number
};

export type ResDataEdit<T> = ResDataCreate<T> | ResDataUpdate<T> | ResDataDelete;