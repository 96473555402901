import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../app/store';
import * as resTypes from './types';
import * as apiTypes from '../types';
import *  as utils from '../utils';
import { response } from 'msw';

const resApiUrl = import.meta.env.VITE_RES_API_URL;

export const resApi = createApi({
	reducerPath: 'resApi',
	baseQuery: fetchBaseQuery({
		baseUrl: resApiUrl,
		prepareHeaders: (headers, {getState}) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getResType: builder.query<string[], void>({
			query: () => ``,
			transformResponse: (response: resTypes.ResAPISchema) => {
				const types: string[] = Object.keys(response.paths).map((path) => (path.replace('/res-api/', '')));
				return types
			}
		}),
		getResObject: builder.query<apiTypes.APIData<resTypes.PossibleResData>, string>({
			query: (resType: string) => `${resType}`,
			transformResponse: (response: resTypes.PossibleResData[]) => {
				const columns = utils.transformApiColumns(response[0]);
				return {
					columns: columns,
					data: response
				}
			}
		}),
		updateResObject: builder.mutation<resTypes.PossibleResData, resTypes.ResDataUpdate<resTypes.PossibleResData>>({
			query: (payload: resTypes.ResDataUpdate<resTypes.PossibleResData>) => ({
				url: `${payload.tableName}?${new URLSearchParams({pk: `${payload.pk}`}).toString()}`,
				method: "PATCH",
				body: payload.value
			}),
		})
	})
})

export const { 
	useGetResTypeQuery,
	useGetResObjectQuery,
	useUpdateResObjectMutation
} = resApi;