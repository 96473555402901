import { APITableColumn, APIColumnType } from "./types";

export const transformApiColumns = (obj: any): APITableColumn[] => {
	const columns = []
	for (const key in obj){
		const column = {
			name: key,
			label: key,
			type: undefined as APIColumnType,
			customBodyRenderLite: null
		} as APITableColumn;
		if (obj.hasOwnProperty(key)) {
			const item = obj[key];
			if (typeof item === "object" && item !== null ){
				column.type = 'json'
			} else {
				column.type = typeof item as APIColumnType;
			}
		}
		columns.push(column)
	}
	return columns
}