import { Navigation } from "./navigation";
import ErrorPage from "./error";
import  {Res}  from "../res/res";
import {
	NonIndexRouteObject,
	createBrowserRouter
} from "react-router-dom";

export interface NamedRouteObject extends NonIndexRouteObject {
	name: string,
	hidden?: boolean
}

export const routes: NamedRouteObject[] = [
	{
		path: "/",
		name: "Home",
		element: <Res/>
	},
	{
		path: "/oauth2-redirect",
		name: "redirect",
		element: <Res/>,
		hidden: true
	},
	{
		path: "/test",
		name: "Test",
		element: (<div>
					HELLLOOO
				</div>
		)
	}
]

export const router = createBrowserRouter(routes.map((route) => {
	route.element = <Navigation routes={routes} children={route.element}/>
	route.errorElement = <ErrorPage/>
	return route
}));