import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store";
import { resApi } from "../../api/res/resApi";
import * as types from '../../api/res/types';
import { APITableColumn, APIColumnType, APIData } from "../../api/types";

const selectedType = "currency_base";

export interface ResState {
	selectedType: string,
	resValue: APIData<types.PossibleResData> | null;
	resTableIsLoading: boolean;
	stagedEdits: types.ResDataEdit<object>[];
};

export const initialState: ResState = {
	selectedType: selectedType,
	resValue: null,
	resTableIsLoading: true,
	stagedEdits: []
}

export const resSlice = createSlice({
	name: "res",
	initialState,
	reducers: {
		setSelectedType: (state, action: PayloadAction<string>) => {
			state.selectedType = action.payload;
		},
		pushStagedEdit: (state, action: PayloadAction<types.ResDataEdit<object>>) =>{
			state.stagedEdits.push(action.payload);
			if (state.resValue?.data){
				if (action.payload && types.isResDataUpdate(action.payload)){
					const payload: types.ResDataUpdate<object> = action.payload;
					state.resValue.data = state.resValue.data.map((d: any)=>{
						if (d.pk !== payload.pk) return d;
						d.isEdited = d?.isEdited ? d.isEdited : {};
						for (let key of Object.keys(payload.value)){
							d[key] = (payload.value as any)[key]; 
							d.isEdited[key] = true;
						}
						return d;
					})
				}
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(resApi.endpoints.getResObject.matchFulfilled, (state, action) => {
				state.resTableIsLoading = false;
			})
			.addMatcher(resApi.endpoints.getResObject.matchRejected, (state) => {
				state.resTableIsLoading = false;
			  });
	}
})

export const {
	setSelectedType, 
	pushStagedEdit
} = resSlice.actions

export const selectSelectedType = (state: RootState) => state.res.selectedType;
export const selectResValue = (state: RootState) => state.res.resValue;
export const selectStagedEdits = (state: RootState) => state.res.stagedEdits;

export default resSlice.reducer;