import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import ListItemText from '@mui/material/ListItemText';
import { useGetResTypeQuery } from '../../api/res/resApi';
import { ListItemButton } from '@mui/material';
import { useGetResObjectQuery } from '../../api/res/resApi';
import Box from '@mui/material/Box';
import { selectSelectedType, setSelectedType } from './resSlice';
import { useEffect } from 'react';

export default function ResTypeList() {
	const { data: resTypes } = useGetResTypeQuery();
	const selectedType = useAppSelector(selectSelectedType);
	const { refetch, isFetching } = useGetResObjectQuery(selectedType);
	// refetch the res object every time selectedType changes
	useEffect(() => {
		if (selectedType && !isFetching) {
			refetch();
		}
	}, [selectedType]);
	const dispatch = useAppDispatch();
	return (
		<List>
			{(resTypes || []).map((type) => (
				<ListItemButton
					key={`item-${type}`}
					selected={selectedType === type}
					onClick={() => dispatch(setSelectedType(type))}
				>
					<ListItemText primary={type} />
				</ListItemButton>
			))}
		</List>
		
	);
}