import * as React from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions, TablePagination } from "mui-datatables";
import { APITableColumn, EditType, APIColumnType } from '../../api/types';
import TextField from '@mui/material/TextField';
import { useAppDispatch } from '../../app/hooks';
import { Box, Tooltip } from '@mui/material';
import { StagedEdit, StagedEdits, StagedEditUpdate } from './stagedEdits';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { NeonGreenButton, NeonOrangeBox, NeonRedButton } from '../styled/neon';
import { AddCircle } from '@mui/icons-material';
import { InsertRowTable } from './insertTable';


export interface APITableProps<T> {
	apiTableName: string,
	title: string | null,
	columns: APITableColumn[] | null,
	data: T[],
	useUpdateMutation: any
};

export const APITable: React.FC<any>= (props: APITableProps<any>) => {
	const {apiTableName, title, columns, data, useUpdateMutation} = props;
	const [localData, setLocalData] = React.useState([] as any[]);
	const [localColumns, setLocalColumns] = React.useState([] as MUIDataTableColumnDef[]);
	
	const [stagedEdits, setStagedEdits] = React.useState([] as StagedEdit[]);
	const [editsOpen, setEditsOpen] = React.useState(false);
	const editRef = React.useRef<HTMLInputElement | null>(null);

	const [updateResObject, {data: resUpdateData, isLoading: resUpdateLoading, error: resUpdateError}] = useUpdateMutation();

	const [insertRows, setInsertRows] = React.useState([] as any[]);
	const insertRowTableRef = React.useRef<HTMLDivElement>(null);

	
	React.useEffect(() => {
		setLocalData(data);
	}, [data]);

	React.useEffect(() => {
		renderColumns();
	}, [columns, localData]);

	React.useEffect(() => {
		const newEdits = stagedEdits.filter(
			e => e.editType!=='create'
		).concat(
			insertRows.map(
				r => ({
					editType: EditType.Create,
					tableName: apiTableName,
					value: r
					})
			)
		);
		setStagedEdits(newEdits);
	}, [insertRows])
	
	const pushStagedUpdate = (edit: StagedEditUpdate) => {
		const newEdits = stagedEdits.filter(e => 
			!(
				e.editType===EditType.Update && 
				e.tableName===edit.tableName && 
				e.value.columnName===edit.value.columnName && 
				e.value.pk===edit.value.pk
			)).concat(edit);
		setStagedEdits(newEdits);
	};

	const popStagedUpdate = (edit: StagedEditUpdate) => {
		const newEdits = stagedEdits.filter(e => 
			!(
				e.editType===EditType.Update && 
				e.tableName===edit.tableName &&
				e.value.columnName===edit.value.columnName && 
				e.value.pk===edit.value.pk
			)).map(e=>e)
		setStagedEdits(newEdits);
	}

	const applyStagedEdits = () => {
		for (const edit of stagedEdits){
			if (edit.editType === EditType.Update){
				updateResObject({tableName: apiTableName, pk: edit.value.pk, value: {[edit.value.columnName]: edit.value}});
			}
			
			console.log(`${edit.value} ${apiTableName}`)
		}
		setStagedEdits([]);
	}

	const getDefaultLocalColumns = (data: any[]) => {
		return Object.keys(data[0]).map((c) => {
			let localType = 'string' as APIColumnType;
			if (typeof c === 'object'){
				localType = 'json'
			}
			return {
				name: c,
				label: c,
				options: {
					customBodyRenderLite: defaultCellRender({name: c, label: c, type: localType})
					}
			}
		})
	}

	const defaultCellRender = (column: APITableColumn) => (dataIndex: number) => {
		const val = localData[dataIndex]?.[column.name];
		if (val === undefined){
			return ""
		}
		const isEdited = data[dataIndex]?.[column.name] !== val;
		const contents = (column.type === 'json') ? JSON.stringify(val) : `${val}`;
		if (isEdited){
			return <NeonOrangeBox sx={{height: '100%'}}>
				{contents}
			</NeonOrangeBox>
		}
		return contents;
	};

	const renderColumns = () => {
		if (!columns){
			if (data){
				setLocalColumns(getDefaultLocalColumns(data));
			}
		}
		else {
			setLocalColumns(columns.map((c) => ({
				name: c.name,
				label: c.label,
				options: {
					customBodyRenderLite: defaultCellRender({name: c.name, label: c.label, type: c.type})
				}
			})));
		}
	};

	const handleEdit = (dataIndex: number, colName: string, pk: number) => {
		const prevValue = editRef.current?.defaultValue;
		const curValue = editRef.current?.value;
		
		if (editRef && curValue !== prevValue){
			const edit: StagedEdit = {
				editType: EditType.Update,
				tableName: apiTableName,
				value: {
					pk: pk,
					columnName: colName,
					prevValue: data[dataIndex]?.[colName],
					value: curValue
				}
			};
			setLocalData(
				localData.map((val, idx) =>{
					if (idx !== dataIndex) return val;
					const newVal = {...val, [colName]: curValue};
					return newVal;
				})
			);
			if (curValue === data[dataIndex]?.[colName]){
				popStagedUpdate(edit)
			} else {
				pushStagedUpdate(edit);
			}
		}
		renderColumns()
	}

	const editCellRender = (column: APITableColumn) => (dataIndex: number) => {
		const val = localData[dataIndex]?.[column.name];
		const pk = localData[dataIndex]?.pk;
		const editFn = () => handleEdit(dataIndex, column.name, pk);
		return <TextField
			onBlur={() => editFn()}
			onKeyUp={(ev) => { if (ev.key === 'Enter') editFn() }}
			defaultValue={(column.type === 'json') ? JSON.stringify(val) : `${val}`}
			inputRef={editRef} />
	};

	const editInline = (dataIndex: number, colIndex: number) => {
		setLocalColumns(columns ? columns.map(
			(resCol, index) => {
				if (index !== colIndex) return localColumns[index];
				return {
					name: resCol.name,
					label: resCol.label,
					options: {
						customBodyRenderLite: (dIndex: number) => {
							if  (dIndex === dataIndex) {
								return editCellRender(resCol)(dIndex);
							} else {
								return defaultCellRender(resCol)(dIndex);
							}
						}
					}
				}
			}
		) : [])
	};

	const options = {
		resizableColumns: true,
		draggableColumns: {
			enabled: true
		},
		onCellClick: (cellData: any, { colIndex, dataIndex }: any) => {
			editInline(dataIndex, colIndex);
		}
	}

	const addRow = () => {
		const emptyRow = [Object.fromEntries(
			Object.keys(data[0]).map(
				(key) => [key, null]
			)
		)];
		setInsertRows(
			emptyRow.concat(insertRows)
		);
		setTimeout(
			() => insertRowTableRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'}),
			250
		)
		
	}

	const tableFooter = (props: any)=> (
		<Box sx={{display: 'flex',  width: "100%", justifyContent: 'flex-end'}}>
			<TablePagination count={localData.length} {...props}/>
			<Tooltip placement='top' title="Add new row">
				<NeonGreenButton sx={{marginTop: "5px", marginRight: "10px", marginBottom: '6px'}} onClick={addRow}><AddCircle/></NeonGreenButton>
			</Tooltip>
			<Tooltip placement='top' title="Upload edits">
				{ stagedEdits.length > 0 ? <NeonRedButton sx={{marginTop: "5px", marginRight: "10px", marginBottom: '6px'}} onClick={()=>setEditsOpen(!editsOpen)}><FileUploadIcon/></NeonRedButton> : <Box></Box> }
			</Tooltip>
		</Box>
	);

	return (
		<Box>
			<MUIDataTable 
				title={title} 
				columns={localColumns} 
				data={localData} 
				options={options}
				components={{
					TableFooter: tableFooter
				}}
			/>
			<StagedEdits edits={stagedEdits} open={editsOpen} setOpen={setEditsOpen} applyStagedEdits={applyStagedEdits}/>
			<Box ref={insertRowTableRef}>
				<InsertRowTable 
					title={`Insert ${title}`}
					columns={localColumns}
					data={insertRows}
					setData={setInsertRows}
				/>
			</Box>
			
		</Box>
	)
};
