import * as React from 'react';
import { useAppDispatch } from '../../app/hooks';
import {
    ListItem,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import { NeonGreenButton, NeonRedButton } from '../styled/neon';
import CloseIcon from '@mui/icons-material/Close';
import { EditType } from '../../api/types';
import { NeonGreenBox, NeonOrangeBox } from '../styled/neon';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


export interface StagedUpdate {
    pk: number;
    columnName: string;
    prevValue: any;
    value: any;
}

export interface StagedEditCreate {
    editType: EditType.Create;
    tableName: string;
    value: any;
}

export interface StagedEditUpdate {
    editType: EditType.Update;
    tableName: string;
    value: StagedUpdate;
}

export type StagedEdit = StagedEditUpdate | StagedEditCreate;

export interface StagedEditsProps {
    edits: StagedEdit[];
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    applyStagedEdits: any
}


const makeEditItem = (edit: StagedEdit) => {
    if (edit.editType === 'create') {
       return (
            <NeonGreenBox sx={{cursor: 'pointer'}}>
                <AddCircleOutlineIcon sx={{marginRight: '4px'}}/>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {
                                Object.keys(edit.value).map(
                                    k => (
                                        <TableCell>
                                            {`${k}`}
                                        </TableCell>
                                    )
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {
                                Object.keys(edit.value).map(
                                    k => (
                                        <TableCell>
                                            {JSON.stringify(edit.value[k])}
                                        </TableCell>
                                    )
                                )
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </NeonGreenBox>
       )
    } else if (edit.editType === 'update'){
        return (
            <NeonOrangeBox sx={{cursor: 'pointer'}}>
                <EditIcon sx={{marginRight: '4px'}}/>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                pk
                            </TableCell>
                            <TableCell>
                                column
                            </TableCell>
                            <TableCell>
                                update
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {edit.value.pk}
                            </TableCell>
                            <TableCell>
                                {edit.value.columnName}
                            </TableCell>
                            <TableCell>
                                {JSON.stringify(edit.value.prevValue)} <ArrowForwardIcon/>  {JSON.stringify(edit.value)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </NeonOrangeBox>
        )
    }
}

export const StagedEdits: React.FC<any> = (props: StagedEditsProps) => {
	const dispatch = useAppDispatch();
    const {edits, open, setOpen, applyStagedEdits} = props;
    if (edits.length > 0){
        return (
            <Box>
                <Drawer anchor='right' open={open} >
                    {/* Flex container for the entire drawer content */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'black' }}>
                        
                        {/* Flex item for edits, grows to take available space */}
                        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                            {edits.map((e, idx) => (
                                <ListItem key={`${idx}-${e.editType}-${e.tableName}-${e.value}`}>
                                    {makeEditItem(e)}
                                </ListItem>
                            ))}
                        </Box>

                        {/* Flex item for the buttons, positioned at the bottom */}
                        <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                            <Tooltip title='Close edit overlay'>
                                <NeonRedButton onClick={() => setOpen(false)}>
                                    <CloseIcon />
                                </NeonRedButton>
                            </Tooltip>
                            <Tooltip title='Upload changes'>
                                <NeonGreenButton onClick={() => applyStagedEdits()}>
                                    <FileUploadIcon />
                                </NeonGreenButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Drawer>
            </Box>


            
        )
    }
	return <Box></Box>
}