import Grid from "@mui/material/Grid";

export default function ErrorPage() {
	return (
			<Grid container direction="column" justifyContent="center" alignContent="center" style={{minHeight: "100vh"}}>
				<Grid item xs={12} sx={{fontSize: 200}}  alignSelf="center" > 
					😨
				</Grid>
				<Grid item xs={12} sx={{fontSize: 100}} alignSelf="center" >
					An unexpected error occurred
				</Grid>
			</Grid>
	)
}