
import * as React from 'react';
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import { APITableColumn, APIColumnType } from '../../api/types';
import TextField from '@mui/material/TextField';


export interface InsertRowTableProps<T> {
	title: string | null,
	columns: APITableColumn[] | null,
	data: T[],
	setData: React.Dispatch<React.SetStateAction<T[]>>
};

export const InsertRowTable: React.FC<any>= (props: InsertRowTableProps<any>) => {
	const {title, columns, data, setData} = props;
	const [localColumns, setLocalColumns] = React.useState([] as MUIDataTableColumnDef[]);
	
	const editRef = React.useRef<HTMLInputElement | null>(null);

	React.useEffect(() => {
		renderColumns();
	}, [columns, data]);

	const getDefaultLocalColumns = (data: any[]) => {
		return Object.keys(data[0]).map((c) => {
			let localType = 'string' as APIColumnType;
			if (typeof c === 'object'){
				localType = 'json'
			}
			return {
				name: c,
				label: c,
				options: {
					customBodyRenderLite: defaultCellRender({name: c, label: c, type: localType})
					}
			}
		})
	}

	const defaultCellRender = (column: APITableColumn) => (dataIndex: number) => {
		const val = data[dataIndex]?.[column.name];
		if (val === undefined){
			return ""
		}
		const contents = (column.type === 'json') ? JSON.stringify(val) : `${val}`;
		return contents;
	};

	const renderColumns = () => {
		if (!columns){
			if (data){
				setLocalColumns(getDefaultLocalColumns(data));
			}
		}
		else {
			setLocalColumns(columns.map((c) => ({
				name: c.name,
				label: c.label,
				options: {
					customBodyRenderLite: defaultCellRender({name: c.name, label: c.label, type: c.type})
				}
			})));
		}
	};

	const handleEdit = (dataIndex: number, colName: string) => {
		const prevValue = editRef.current?.defaultValue;
		const curValue = editRef.current?.value;
		if (editRef && curValue !== prevValue){
			setData(
				data.map((val, idx) =>{
					if (idx !== dataIndex) return val;
					const newVal = {...val, [colName]: curValue};
					return newVal;
				})
			);
		}
		renderColumns()
	}

	const editCellRender = (column: APITableColumn) => (dataIndex: number) => {
		const val = data[dataIndex]?.[column.name];
		const editFn = () => handleEdit(dataIndex, column.name);
		return <TextField
			onBlur={() => editFn()}
			onKeyUp={(ev) => { if (ev.key === 'Enter') editFn() }}
			defaultValue={(column.type === 'json') ? JSON.stringify(val) : `${val}`}
			inputRef={editRef} />
	};

	const editInline = (dataIndex: number, colIndex: number) => {
		setLocalColumns(columns ? columns.map(
			(resCol, index) => {
				if (index !== colIndex) return localColumns[index];
				return {
					name: resCol.name,
					label: resCol.label,
					options: {
						customBodyRenderLite: (dIndex: number) => {
							if  (dIndex === dataIndex) {
								return editCellRender(resCol)(dIndex);
							} else {
								return defaultCellRender(resCol)(dIndex);
							}
						}
					}
				}
			}
		) : [])
	};

	const options = {
		resizableColumns: true,
		draggableColumns: {
			enabled: true
		},
		onCellClick: (cellData: any, { colIndex, dataIndex }: any) => {
			editInline(dataIndex, colIndex);
		}
	}
	if (data.length > 0){
		return (
			<MUIDataTable 
				title={title} 
				columns={localColumns} 
				data={data} 
				options={options}
				components={
					{
						TableFooter: ()=><div></div>,
						TableToolbar: ()=><div><h2>Insert Rows</h2></div>
					}
				}
			/>
		)
	}
};
