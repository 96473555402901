import * as React from "react";
import ResTypeList from './resSelect';
import { Box, Grid } from "@mui/material";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectSelectedType, selectResValue, selectStagedEdits } from "./resSlice";
import { resApi, useGetResObjectQuery, useUpdateResObjectMutation } from "../../api/res/resApi";
import { APITable } from "../../components/apiTable/apiTable";
import LinearProgress from '@mui/material/LinearProgress';


export const Res = () => {
	const selectedType = useAppSelector(selectSelectedType);
	const stagedEdits = useAppSelector(selectStagedEdits);
	const {data: resValue, isFetching, isLoading} = useGetResObjectQuery(
		selectedType
	);
	const dispatch = useAppDispatch();
	if (!resValue) { dispatch(resApi.endpoints.getResObject.initiate(selectedType)) };
		return <Grid container spacing={2} sx={{ mt: "20px" }}>
		<Grid item xs={4} maxHeight="80vh" overflow="auto">
			<ResTypeList />
		</Grid>
		<Grid item xs={8} maxHeight="80vh" overflow="auto">
			{
				((isFetching || isLoading)) ? 
					<Box><LinearProgress color='secondary'/></Box>
				 : 
				 (<APITable
					apiTableName={selectedType}
					title={selectedType}
					columns={resValue?.columns || []}
					data={resValue?.data || []}
					useUpdateMutation={useUpdateResObjectMutation}
				/>)
			}
		</Grid>
	</Grid>;
}