export type APIColumnType = 'string' | 'integer' | 'json' | undefined;

export interface APITableColumn {
	name: string,
	label: string,
	type: APIColumnType | undefined
}

export interface APIData<T> {
	columns: APITableColumn[] | null,
	data: T[]
}


export enum EditType {
	Create = 'create',
	Update = 'update',
	Delete = 'delete'
};