import React, { useContext } from "react";
import { AuthContext, AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent, IAuthContext } from "react-oauth2-code-pkce";
import Button  from "@mui/material/Button";
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';
import Grid  from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { setToken } from "./authSlice";
import { AppDispatch } from "../../app/store";
import { useAppDispatch } from "../../app/hooks";

const authConfig: TAuthConfig = {
  clientId: import.meta.env.VITE_CLIENT_ID,
  authorizationEndpoint: import.meta.env.VITE_AUTH_ENDPOINT,
  tokenEndpoint: import.meta.env.VITE_TOKEN_ENDPOINT,
  redirectUri: import.meta.env.VITE_REDIRECT_URL,
  scope: import.meta.env.VITE_OAUTH_SCOPE,
  autoLogin: false,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => window.confirm('Session expired. Refresh page to continue using the site?') && event.login(),
}

const LoginPage: React.FC<any> = ({children}) => {
	const {token, login} = useContext<IAuthContext>(AuthContext);
	const dispatch = useAppDispatch();
	if (token){
		dispatch(setToken(token));
		return children;
	} else {
		return (<Box display="flex" alignItems="center" minHeight="100vh">
			<Grid container justifyContent="center">
				<Grid item>
					<DoNotDisturbRoundedIcon sx={{fontSize: 200, width: "100vw"}}/>
				</Grid>
				<Grid item>
					<Button onClick={() => login()} sx={{ fontSize: 100}}>LOG IN</Button>
				</Grid>
			</Grid>
		</Box>
		)
	}
}

export const OAuthProvider: React.FC<any>= ({children}) => (
	<AuthProvider authConfig={authConfig}>
		<LoginPage>
			{children}
		</LoginPage>
	</AuthProvider>
);
