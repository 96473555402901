import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/Button';
import Box from '@mui/material/Box';

export const createNeonStyle = (color: string) => ({
	color: '#fff', // White text color
	backgroundColor: `secondary`, // Background color
	boxShadow: `0 0 5px ${color}, 0 0 2px ${color}, 0 0 5px ${color}`, // Normal glowing effect
	display: 'flex',
	padding: '5px',
	transition: 'box-shadow 0.3s ease-in-out, text-shadow 0.3s ease-in-out', // Smooth transition for the glow effect
	'&:hover': {
		backgroundColor: 'secondary', // Background color,
		boxShadow: `0 0 8px ${color}, 0 0 6px ${color}, 0 0 10px ${color}`, 
	}
});

export const neonRedStyle = createNeonStyle('#FF0000');
export const neonGreenStyle = createNeonStyle('#00FF00');
export const neonBlueStyle = createNeonStyle('#099FFF');
export const neonOrangeStyle = createNeonStyle('#FF6600');
export const neonPinkStyle = createNeonStyle('#FF00FF');

export const NeonRedButton = styled(IconButton)(neonRedStyle);
export const NeonGreenButton = styled(IconButton)(neonGreenStyle);
export const NeonBlueButton = styled(IconButton)(neonBlueStyle);
export const NeonOrangeButton = styled(IconButton)(neonOrangeStyle);
export const NeonPinkButton = styled(IconButton)(neonPinkStyle);

export const NeonRedBox = styled(Box)(neonRedStyle);
export const NeonGreenBox = styled(Box)(neonGreenStyle);
export const NeonBlueBox = styled(Box)(neonBlueStyle);
export const NeonOrangeBox = styled(Box)(neonOrangeStyle);
export const NeonPinkBox = styled(Box)(neonPinkStyle);