import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./app/store"
import { theme } from './theme';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { OAuthProvider } from "./features/auth/Auth";
import { router } from "./features/route/router"

ReactDOM.createRoot(document.getElementById("root")!).render(<React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <OAuthProvider>
        <RouterProvider router={router} />
      </OAuthProvider>
    </ThemeProvider>
    </Provider>
</React.StrictMode>)
